/**
 * Indica si un link es link externo
 * 
 * @author Abraham Flores
 * @param {String}  likToTest           Link a verificar
 * @return {Boolean}
 */
export const isExternalLink = (likToTest) => {
    return likToTest.toLowerCase().startsWith('https://') || likToTest.toLowerCase().startsWith('http://')
}

/**
 * Obtiene las clases que se asociarán a un componente a partir de ciertas condiciones
 * 
 * @param {Object} conditions     Es un objeto con la estructura {classToApply: conditionForApply, ...}
 * @param {String} additionalClasses     Clases adicionales a agregar al final
 * @returns {String}              Las clases separadas por espacio que cumplen con las condiciones para aplicar    
 */
export const getAllowedClasses = (conditions, additionalClasses = null) => {
    const classList = Object.keys(conditions).filter(className => conditions[className]).map(className => className)
    if(additionalClasses) classList.push(additionalClasses)
    
    return classList.join(' ')
}

/**
 * Decide como se incluirá contenido en un componente dependiendo del tipo del contenido
 * ya que comúnmente se pasa como un string HTML pero puede haber ocasiones en las que se
 * mande un componente/s de react
 * 
 * @param {Object|String} childrenOrHTML 
 * @returns {Object}
 */
export const getChildrenOrHTMLProp = (childrenOrHTML) => {
    const props = {}

    if(typeof childrenOrHTML === 'string'){
        props.dangerouslySetInnerHTML = {__html: childrenOrHTML}
    }else{
        props.children = childrenOrHTML
    }

    return props
}

/**
 * Devuelve el src real a usar para una imagen tomando en cuenta
 * que puede ser una url normal, un objeto generado por GraphQL para devolver
 * webp o una imagen svg pasada como objeto en publicURL
 * 
 * @param {String|Object} arc
 * @return {String}
 */
export const getRealSrc = (src) => {
    if(typeof src === 'string'){
        return src
    }else if(typeof src === 'object' && src.childImageSharp && src.childImageSharp.fluid.srcWebp){
        return src.childImageSharp.fluid.srcWebp
    }else if(typeof src === 'object' && src.publicURL){
        return src.publicURL
    }

    return null
}