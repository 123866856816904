export const HS_CONTACT_FORM = {
    name: 'contact_form',
    portalId: '6180490',
    formId: 'd226898b-338f-4f46-bc88-ec745ebdcd59',
    id: 'hubspot-contact-form'
    //onSubmit: 
    //onReady
    //loading
}

/**
 * Se deben agregar todos los forms
 * creados para habilitarlos en la búqueda
 */
const allForms = [
    HS_CONTACT_FORM
]

/**
 * Devuelve los settings de un form de Hubspot
 * a partir de su nombre aosicado
 * 
 * @param {String} name         Nombre asociado
 * @returns {Object}
 */
export const resolveFormByName = (name) => {
    return allForms.find(form => form.name == name)
}