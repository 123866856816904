import React, {useEffect} from 'react'
import { Image } from '..'
import close from '../../assets/images/close.svg'

export default function Modal({children, isOpen = false, onClose = () => {}, ...attrs}) {
    useEffect(() => {
        if(isOpen){
            document.body.style.overflowY = 'hidden'
        }else{
            document.body.style.overflowY = 'scroll'
        }
    }, [isOpen])

    return isOpen ? <div className="fixed top-0 left-0 right-0 bottom-0 bg-primary bg-opacity-30 flex flex-col items-center overflow-y-scroll z-20" {...attrs}>
        <div className="w-11/12 md:w-10/12 lg:w-8/12 p-5 md:p-10 lg:p-20 bg-white rounded relative my-10">
            <Image src={close} className="absolute top-3 sm:top-10 right-3 sm:right-10 cursor-pointer" onClick={onClose}/>
            {children}
        </div>
    </div> : ''
}
